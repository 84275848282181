// AllOffersView.js
import React, { useEffect, useState, useCallback } from "react";
import { GraphQLClient } from "graphql-request";
import "./AllOffersView.css";
import Web3 from 'web3';
const web3 = new Web3();


const AllOffersView = ({ realTokenPropertiesData }) => {
    const [data, setData] = useState([]);
    const [groupedOffers, setGroupedOffers] = useState({});
    const [countSoFar, setCountSoFar] = useState(0);
    const [isLoading, setIsLoading] = useState(true);


    function convertPriceToDollars(buyerToken, price) {
        const USDC_ADDRESS = '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83';
        const WXDAI_ADDRESS = '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d';

        let priceInDollars;
        buyerToken = String(buyerToken).toLowerCase(); // Cast to string and convert to lowercase
        if (buyerToken.toLowerCase() === USDC_ADDRESS.toLowerCase()) {
            priceInDollars = price / 10 ** 6; // 6 decimals for USDC
        } else if (buyerToken.toLowerCase() === WXDAI_ADDRESS.toLowerCase()) {
            priceInDollars = price / 10 ** 18; // 18 decimals for wxDai
        } else {
            //console.error('Unsupported buyerToken:', buyerToken);
            return price;
        }

        return priceInDollars.toFixed(2); // Round to 2 decimal places
    }


    const fetchData = useCallback(async () => {
        const endpoint = "https://api.thegraph.com/subgraphs/name/jycssu-com/yam-history-gnosis";
        const client = new GraphQLClient(endpoint);

        const offers = [];
        let skip = 0;
        let hasMore = true;

        while (hasMore) {
            const query = `
        query ActivePublicOffers {
          offers(
            orderBy: createdAtTimestamp
            orderDirection: desc
            where: { isActive: true, isPrivate: false }
            first: 100
            skip: ${skip}
          ) {
            id
            type
            offerToken {
              id
            }
            buyerToken {
              id
            }
            price
            quantityAvailable
          }
        }
      `;

            try {
                const response = await client.request(query);
                offers.push(...response.offers);
                skip += 100;
                hasMore = response.offers.length === 100;
                setCountSoFar(skip);
            } catch (error) {
                console.error("Error fetching data:", error);
                hasMore = false;
            }
        }

        setData(offers);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    useEffect(() => {
        const groupOffersByProperty = (offers) => {
            const groupedOffers = offers.reduce((groupedOffers, offer) => {
                const propertyKey = realTokenPropertiesData.find(
                    (property) => property.gnosisContract.toLowerCase() === offer.offerToken.id.toLowerCase()
                );

                if (propertyKey && !groupedOffers[propertyKey.gnosisContract]) {
                    groupedOffers[propertyKey.gnosisContract] = { offers: [], volume: 0 };
                }

                if (propertyKey) {
                    const volume = convertPriceToDollars(offer.buyerToken.id, offer.price) * parseFloat(web3.utils.fromWei(offer.quantityAvailable));
                    groupedOffers[propertyKey.gnosisContract].offers.push({ ...offer, volume });
                    groupedOffers[propertyKey.gnosisContract].volume += volume;
                }

                return groupedOffers;
            }, {});

            // Sort the offers by volume in descending order
            Object.values(groupedOffers).forEach((group) => {
                group.offers.sort((a, b) => b.volume - a.volume);
            });

            return groupedOffers;
        };

        setGroupedOffers(groupOffersByProperty(data));
    }, [data, realTokenPropertiesData]);


    return (
        <div className="all-offers-view-container">
            {isLoading && <p>Loading offers ({countSoFar})...</p>}
            <table className="all-offers-view-table">
                <thead>
                    <tr>
                        <th>Property Name</th>
                        <th>Image</th>
                        <th>Offers</th>
                    </tr>
                </thead>
                <tbody>
                    {realTokenPropertiesData
                        .sort((a, b) => (groupedOffers[b.gnosisContract]?.volume || 0) - (groupedOffers[a.gnosisContract]?.volume || 0))
                        .map((property) => {
                            const propertyOffers = groupedOffers[property.gnosisContract];
                            if (!propertyOffers) return null;

                            return (
                                <tr key={property.uuid}>
                                    <td>{property.shortName}</td>
                                    <td>
                                        <img src={property.imageLink} alt={property.shortName} className="property-image" />
                                    </td>
                                    <td>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Offer ID</th>
                                                    <th>Quantity of Tokens</th>
                                                    <th>Price of Token</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {propertyOffers.offers.map((offer) => (
                                                    <tr key={offer.id}>
                                                        <td>
                                                            <a href={`https://yam.realtoken.network/offer/${offer.id}`} target="_blank" rel="noopener noreferrer">{offer.id}</a>
                                                        </td>
                                                        <td>{parseFloat(web3.utils.fromWei(offer.quantityAvailable)).toFixed(2)}</td>
                                                        <td>${convertPriceToDollars(offer.buyerToken.id, offer.price)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>

            </table>
        </div>
    );
};

export default AllOffersView;



/*async function fetchData() {
  const endpoint = 'https://api.thegraph.com/subgraphs/name/jycssu-com/yam-history-gnosis';
  const client = new GraphQLClient(endpoint);
 
  const query = `
    query ActivePublicOffers {
      offers(
        orderBy: createdAtTimestamp
        orderDirection: desc
        where: { isActive: true, isPrivate: false }
      ) {
        id
        type
        offerToken {
          id
        }
        buyerToken {
          id
        }
        price
        quantityAvailable
      }
    }
  `;
 
  try {
    const response = await client.request(query);
    setData(response.offers);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}*/

/*const groupOffersByProperty = (offers) => {
return offers.reduce((groupedOffers, offer) => {
  const propertyKey = realTokenPropertiesData.find(
    (property) => property.gnosisContract.toLowerCase() === offer.offerToken.id.toLowerCase()
  );
 
  if (propertyKey && !groupedOffers[propertyKey.gnosisContract]) {
    groupedOffers[propertyKey.gnosisContract] = [];
  }
 
  if (propertyKey) {
    groupedOffers[propertyKey.gnosisContract].push(offer);
  }
 
  return groupedOffers;
}*/
