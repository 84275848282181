/*const Header = ({ currentBlockNumber, yamOffersLast24Hours, soundEnabled, toggleSound }) => {
  const currentTime = new Date().toUTCString().replace(/ GMT$/, " UTC");
  const headerInfo = `Current time: ${currentTime} | Current block: ${currentBlockNumber || '...'} | Yam offers created last 24 hours: ${yamOffersLast24Hours || 0}`;

  return (
    <header className="header">
      <h1>
        <span className="white">REAL</span>
        <span className="orange">T</span>
        <span className="white">SCAN</span>
      </h1>
      <span className="header-info">{headerInfo}</span>
      <div
        className="sound-toggle"
        onClick={toggleSound}
        style={{ color: soundEnabled ? 'limegreen' : 'gray' }}
      >
        {soundEnabled ? '🔊' : '🔇'}
      </div>
    </header>
  );
};

export default Header;*/
// Header.js

import React from 'react';
import './Header.css';

const Header = ({
  currentBlockNumber,
  yamOffersLast24Hours,
  soundEnabled,
  toggleSound,
  activeView,
  setActiveView,
}) => {
  const currentTime = new Date().toUTCString().replace(/ GMT$/, ' UTC');
  const headerInfo = `Current time: ${currentTime} | Current block: ${currentBlockNumber || '...'} | Yam offers created last 24 hours: ${yamOffersLast24Hours || 0}`;

  return (
    <header className="header">
      <h1>
        <span className="white">REAL</span>
        <span className="orange">T</span>
        <span className="white">SCAN</span>
      </h1>
      <span className="header-info">{headerInfo}</span>
      <div
        className="sound-toggle"
        onClick={toggleSound}
        style={{ color: soundEnabled ? 'limegreen' : 'gray' }}
      >
        {soundEnabled ? '🔊' : '🔇'}
      </div>
      <div className="view-options">
        <button
          className={`live-market-btn ${activeView === 'liveMarket' ? 'active' : ''}`}
          onClick={() => setActiveView('liveMarket')}
        >
          Live Market
        </button>
        <button
          className={`all-offers-btn ${activeView === 'offers' ? 'active' : ''}`}
          onClick={() => setActiveView('offers')}
        >
          Offers
        </button>
        <button
          className={`market-explorer-btn ${activeView === 'marketExplorer' ? 'active' : ''}`}
          onClick={() => setActiveView('marketExplorer')}
        >
          Market Explorer
        </button>
      </div>
    </header>
  );
};

export default Header;
