import React from 'react';
import './DetailsView.css';
import ChatComponent from './ChatComponent';
import PropertyInfo from './PropertyInfo';

const web3 = require("web3");
function convertPriceToDollars(buyerToken, price) {
  const USDC_ADDRESS = "0xddafbb505ad214d7b80b1f830fccc89b60fb7a83";
  const WXDAI_ADDRESS = "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d";

  let priceInDollars;
  buyerToken = String(buyerToken).toLowerCase(); // Cast to string and convert to lowercase
  if (buyerToken.toLowerCase() === USDC_ADDRESS.toLowerCase()) {
    priceInDollars = price / 10 ** 6; // 6 decimals for USDC
  } else if (buyerToken.toLowerCase() === WXDAI_ADDRESS.toLowerCase()) {
    priceInDollars = price / 10 ** 18; // 18 decimals for wxDai
  } else {
    console.error("Unsupported buyerToken:", buyerToken);
    return price;
  }

  return priceInDollars.toFixed(2); // Round to 2 decimal places
}

function createOfferLink(id, offerType) {
  /*console.log("offerType " + offerType);
  if (offerType === "YAM") {
    return `https://yam.realtoken.network/offer/${id}`;
  } else if (offerType === "swapcat") {
    return `https://swap.cat/?oid=${id}&chain=100`;
  } else {
    console.error("Unsupported offer type:", offerType);
    return "";
  }*/
  return `https://yam.realtoken.network/offer/${id}`;
}


const DetailsView = ({ propertyTokenAddress, sellerOffers, realTokenProperties }) => {
  //console.log("DetailsView props: ", { propertyTokenAddress, sellerOffers, realTokenProperties });
  return (
    <div className="details-view-container">
      <ChatComponent />
      {propertyTokenAddress || sellerOffers ? (
        <>
          {propertyTokenAddress && (
            <PropertyInfo 
              propertyInfo={realTokenProperties}
              propertyTokenAddress={propertyTokenAddress}
            />
          )}
          {sellerOffers && (
            <div className="details-view-content">
              <h2>Seller Offers</h2>
              <table className="details-view-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Price</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {sellerOffers.map((offer, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                      <td>
                        <a href={createOfferLink(offer.id, 0)} target="_blank" rel="noopener noreferrer">
                          {offer.id}
                        </a>
                      </td>
                      <td>{convertPriceToDollars(offer.buyerToken.id, offer.price)}</td>
                      <td>{parseFloat(web3.utils.fromWei(offer.quantity)).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <div className="details-view-content">Details View: Waiting for selection</div>
      )}
    </div>
  );
};

export default DetailsView;
