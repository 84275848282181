import React, { useState, useEffect, useRef } from 'react';
import './ChatComponent.css';
import Web3 from 'web3';
import axios from 'axios';

const ChatComponent = () => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [userAddress, setUserAddress] = useState('');
    const messagesEndRef = useRef(null);
    const [isMinimized, setIsMinimized] = useState(false);

    /*const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };*/

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get('/.netlify/functions/getMessages');
            const messages = response.data.rows.reverse();
            setMessages(messages);
            //console.log('Fetched messages:', messages);
        };

        fetchData();
    }, []);


    //useEffect(scrollToBottom, [messages]);

    const authenticate = async () => {
        if (window.ethereum) {
            try {
                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.requestAccounts();
                const message = `Authenticate user: ${accounts[0]}`;
                const signature = await web3.eth.personal.sign(message, accounts[0]);

                const response = await axios.post('/.netlify/functions/authenticate', {
                    address: accounts[0],
                    signature
                });

                if (response.data.success) {
                    setUserAddress(accounts[0]);
                    setIsConnected(true);
                } else {
                    alert('Authentication failed. Please try again.');
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            alert('Please install MetaMask to use this feature.');
        }
    };


    const sendMessage = async () => {
        if (inputMessage.trim() === '') {
            return;
        }

        const username = `${userAddress.slice(0, 6)}...${userAddress.slice(-4)}`;
        const content = inputMessage;

        console.log('Username:', username); // Debug
        console.log('Content:', content); // Debug

        try {
            const response = await axios.post('/.netlify/functions/sendMessage', {
                username: userAddress,
                content: inputMessage,
            });

            console.log('sendMessage response:', response);

            if (response.data.success) {
                const newMessage = response.data.message;
                setMessages([
                    {
                        username: `${newMessage.userAddress.slice(0, 6)}...${newMessage.userAddress.slice(-4)}`,
                        content: newMessage.content,
                        created_at: newMessage.created_at,
                    },
                    ...messages,
                ]);
                setInputMessage('');
            } else {
                alert('There was an error sending your message. Please try again.');
            }
        } catch (error) {
            console.error('sendMessage error:', error); // Debug
            alert('There was an error sending your message. Please try again.');
        }
    };




    return (
        <div className={`chat-container ${isMinimized ? 'minimized' : ''}`}>
            <div className="toggle-minimize" onClick={toggleMinimize}>
                {isMinimized ? 'Open Live Chat ▼' : 'Close Live Chat ▲'}
            </div>
            {!isMinimized && (
                <>
                    <div className="chat-messages">
                        {messages &&
                            messages.map((msg, idx) => (
                                <div key={idx} className="message">
                                    <span className="timestamp">
                                    <strong>&lt;{new Date(msg.created_at).toLocaleTimeString('en-US', { hour12: false })}</strong>
                                    </span>
                                    <span className="username">
                                    <strong> 0x{msg.username.slice(-4)}&gt;</strong>&nbsp;
                                    </span>
                                    <span className="content">{msg.content}</span>
                                </div>
                            ))}
                        <div ref={messagesEndRef} />
                    </div>

                    {isConnected ? (
                        <div className="chat-input">
                            <input
                                type="text"
                                placeholder="Type your message..."
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        sendMessage();
                                    }
                                }}
                            />
                            <button onClick={sendMessage}>Send</button>
                        </div>
                    ) : (
                        <div className="connect-wallet">
                            <button onClick={authenticate}>Connect Wallet</button>
                        </div>
                    )}
                </>
            )}
        </div>
    );

};

export default ChatComponent;
