// components/Footer.js
import React from 'react';

const Footer = () => (
  <footer className="footer">
    This web application is not affiliated with RealToken Inc. Please visit{' '}
    <a
      href="https://realt.co"
      className="footer-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      realt.co
    </a>{' '}
    if you are looking for official information.
  </footer>
);

export default Footer;
