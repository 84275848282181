
import React, { useState, useEffect } from 'react';
import './MarketExplorerView.css';

const MarketExplorerView = ({ realTokenProperties, onPropertyClick }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [minYield, setMinYield] = useState(0);

  useEffect(() => {
    const searchResults = realTokenProperties.filter(
      (property) =>
        property.shortName.toLowerCase().includes(searchText.toLowerCase()) &&
        ((property.netRentYearPerToken / property.tokenPrice) * 100) >= minYield
    );
    setFilteredProperties(searchResults);
  }, [searchText, minYield, realTokenProperties]);

  return (
    <div className="market-explorer-view">
      <input
        type="text"
        className="search-box"
        placeholder="Search properties"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <input
        type="number"
        className="min-yield-input"
        min="0"
        step="0.1"
        placeholder="Min Yield"
        value={minYield}
        onChange={(e) => setMinYield(parseFloat(e.target.value))}
      />
      <p>There are {filteredProperties.length} properties matching:</p>
      <div className="properties-grid">
        {filteredProperties.map((property) => (
          <div
            key={property.uuid}
            className="property-card"
            onClick={() =>
                onPropertyClick(
                  property,
                  'property',
                  property.gnosisContract 
                )
              }
          >
            <img
              src={property.imageLink}
              alt={property.shortName}
              className="property-image"
            />
            <div className="property-details">
              <div className="property-name">{property.shortName}</div>
              <div>Price: {property.tokenPrice} {property.currency}</div>
              <div>
                Yield: {((property.netRentYearPerToken / property.tokenPrice) * 100).toFixed(2)}%
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketExplorerView;
