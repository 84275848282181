import React from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import './OffersTable.css';


// Add this component inside the OffersTable component

const TableRow = ({ row, prepareRow, onSellerClick, onPropertyClick }) => {
  prepareRow(row);

  const handleSellerClick = (e, cell) => {
    e.stopPropagation();
    onSellerClick(cell.value.address);
  };

  const handlePropertyClick = (e, row) => {
    e.stopPropagation();
    onPropertyClick(row.original.property, 'property', row.original.offerTokenAddress);
  };

  const isSold = row.original.type === 'SOLD';
  const isTransfer = row.original.type === 'TRANSFER';

  const getRowBackgroundColor = () => {
    if (isSold) {
      return '#90EE90'; // green color for SOLD rows
    }
    if (isTransfer) {
      return '#ADD8E6'; // light blue color for TRANSFER rows
    }
    return ''; // default color for other rows
  };

  return (
    <tr
    {...row.getRowProps()}
    style={{ backgroundColor: getRowBackgroundColor() }}
    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e5e5e5'}
    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = getRowBackgroundColor()}
  >
      {row.cells.map((cell, index) => {
        if (index === 2) {
          return (
            <td
              {...cell.getCellProps()}
              onClick={(e) => handleSellerClick(e, cell)}
            >
              {cell.render('Cell')}
            </td>
          );
        } else if (index === 6) {
          return (
            <td
              {...cell.getCellProps()}
              onClick={(e) => handlePropertyClick(e, row)}
            >
              {cell.render('Cell')}
            </td>
          );
        }
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
      })}
    </tr>
  );
};



const OffersTable = ({
  data,
  onPropertyClick,
  onSellerClick,
  soundEnabled,
}) => {
  // Define a default filter method
  const defaultColumn = React.useMemo(() => {
    return { Filter: DefaultColumnFilter };
  }, []);

  function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <input
        className="filter-input" // Add this line
        value={filterValue || ''}
        onChange={e => setFilter(e.target.value || undefined)}
        placeholder={`Filter...`}
      />
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'time',
        className: 'time-column', // Add this line
        sortType: (rowA, rowB, columnId) => {
          const a = new Date(rowA.values[columnId]);
          const b = new Date(rowB.values[columnId]);
          return a - b;
        },
        Cell: ({ value }) => {
          const date = new Date(value);
          const formattedDate = `${date.getUTCDate().toString().padStart(2, '0')}/${(date.getUTCMonth() + 1)
            .toString()
            .padStart(2, '0')} ${date
              .getUTCHours()
              .toString()
              .padStart(2, '0')}:${date
                .getUTCMinutes()
                .toString()
                .padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0')}`;
          return formattedDate;
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => {
          //const isSold = value === 'SOLD';
          //const isNew = value === 'NEW';
          return (
            <div
              style={{
                //backgroundColor: isSold ? '#28a745' : isNew ? '#FFFF00' : 'inherit',
                //fontWeight: isSold || isNew ? 'bold' : 'normal',
                fontWeight: 'normal',
                textAlign: 'left',
              }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "Seller",
        accessor: "seller",
        className: "seller-column", // Add this line
        Cell: ({ value, row }) => {
          const seller = value;
          const shortAddress = `0x...${seller.address.slice(-4)}`; // Modified this line
          return (
            <button
              onClick={() => onSellerClick(row.original, 'seller')}
              className="seller-btn"
            >
              {shortAddress}
            </button>
          );
        }
      },
      {
        Header: 'Buyer',
        accessor: 'buyer.shortAddress',
        Cell: ({ row }) => {
          const buyer = row.original.buyer;
          const shortAddress = buyer.address ? `0x...${buyer.address.slice(-4)}` : 'N/A';
          return (
            <a href={`https://gnosisscan.io/address/${buyer.address}`} target="_blank" rel="noopener noreferrer">
              {shortAddress}
            </a>
          );
        },
      },
      {
        Header: 'Market',
        accessor: 'market',
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ row }) => {
          const item = row.original;
          return item.market === "YAM" ? (
            <a
              href={`https://yam.realtoken.network/offer/${item.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.id}
            </a>
          ) : (
            item.id
          );
        },
      },
      {
        Header: "Property",
        accessor: (originalRow) => {
          return originalRow.property;
        },
        className: "property-column",
        Cell: ({ value }) => {
          return (
            <button
              onClick={() => onPropertyClick(value, "property")}
              className="property-btn"
            >
              {value} {/* display the 'value' directly */}
            </button>
          );
        },
        // ...
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        className: "quantity-column",
      },
      {
        Header: 'Price',
        accessor: 'price',
        className: "price-column",
      },
      {
        Header: 'P Diff',
        accessor: 'pDiff',
        Cell: ({ value }) => {
          const isNegative = value < 0;
          return (
            <div
              style={{
                backgroundColor: isNegative ? '#28a745' : 'inherit',
                fontWeight: isNegative ? 'bold' : 'normal',
                color: isNegative ? 'inherit' : '#FF0000',
                textAlign: 'center',
              }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Offer Yield',
        accessor: 'offerYield',
        Cell: ({ value }) => {
          const isGreaterThanTen = value > 10;
          return (
            <div
              style={{
                backgroundColor: isGreaterThanTen ? '#28a745' : 'inherit',
                fontWeight: isGreaterThanTen ? 'bold' : 'normal',
                textAlign: 'center',
              }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Yield Diff',
        accessor: 'yieldDiff',
      },
    ],
    [onPropertyClick, onSellerClick] // Add dependencies here
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { columns, data, defaultColumn, initialState: { sortBy: [{ id: "time", desc: true }] } },
    useFilters,
    useSortBy
  );

  return (
    <table {...getTableProps()} className="offers-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={column.className}
              >
                {column.render("Header")}
                {/* Render a sorting indicator */}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? " 🔽"
                      : " 🔼"
                    : ""}
                </span>
                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => (
          <TableRow
            key={row.id}
            row={row}
            prepareRow={prepareRow}
            onSellerClick={onSellerClick}
            onPropertyClick={onPropertyClick}
          />
        ))}
      </tbody>
    </table>
  );
};

export default OffersTable;