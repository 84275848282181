import React from 'react';
import { GraphQLClient } from 'graphql-request';
import './PropertyInfo.css';

const GRAPHQL_ENDPOINT = 'https://api.thegraph.com/subgraphs/name/jycssu-com/yam-history-gnosis';
const client = new GraphQLClient(GRAPHQL_ENDPOINT);

const web3 = require('web3');

function convertPriceToDollars(buyerToken, price) {
    const USDC_ADDRESS = '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83';
    const WXDAI_ADDRESS = '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d';

    let priceInDollars;
    buyerToken = String(buyerToken).toLowerCase(); // Cast to string and convert to lowercase
    if (buyerToken.toLowerCase() === USDC_ADDRESS.toLowerCase()) {
        priceInDollars = price / 10 ** 6; // 6 decimals for USDC
    } else if (buyerToken.toLowerCase() === WXDAI_ADDRESS.toLowerCase()) {
        priceInDollars = price / 10 ** 18; // 18 decimals for wxDai
    } else {
        console.error('Unsupported buyerToken:', buyerToken);
        return 0;
    }

    return priceInDollars.toFixed(2); // Round to 2 decimal places
}

function createOfferLink(id, offerType) {
    /*console.log("offerType " + offerType);
    if (offerType === "YAM") {
      return `https://yam.realtoken.network/offer/${id}`;
    } else if (offerType === "swapcat") {
      return `https://swap.cat/?oid=${id}&chain=100`;
    } else {
      console.error("Unsupported offer type:", offerType);
      return "";
    }*/
    return `https://yam.realtoken.network/offer/${id}`;
}
const fetchOffersData = async (offerTokenAddress) => {
    const query = `
      query FindOfferWithOffertokenId($offerToken: String!) {
        offers(
          where: {
            offerToken: $offerToken,
            quantity_gt: 0
            isActive: true
          }
        ) {
          id
          price
          quantity
          buyerToken {
            id
          }
          offerToken {
            id
          }
        }
      }
    `;
  
    const variables = { offerToken: offerTokenAddress.toLowerCase() };
    const data = await client.request(query, variables);
    return data;
  };

const PropertyInfo = ({ propertyInfo, propertyTokenAddress }) => {
    const [propertyOffers, setPropertyOffers] = React.useState(null);

    React.useEffect(() => {
        if (propertyTokenAddress) {
            fetchOffersData(propertyTokenAddress).then((data) => setPropertyOffers(data.offers));
        }
    }, [propertyTokenAddress]);

    console.log('PropertyInfo received props:', { propertyInfo, propertyTokenAddress });

    if (!propertyInfo) {
        return null;
    }

    const selectedProperty = propertyInfo.find((property) => property.gnosisContract.toLowerCase() === propertyTokenAddress.toLowerCase());
    console.log("selected property: " + selectedProperty);
    const { fullName, imageLink, tokenPrice, netRentYearPerToken, is_rmm, propertyType, marketplaceLink } = selectedProperty;

    const yieldPerToken = (netRentYearPerToken / tokenPrice) * 100;

    return (
  <div className="property-info-container">
    <h2 className="property-info-title">Property Info: {fullName}</h2>
    <div className="property-info">
      <div className="property-info-left">
        <p className="property-info-text">Price per token: {tokenPrice}</p>
        <p className="property-info-text">Yield per token: {yieldPerToken.toFixed(2)}%</p>
        <p className="property-info-text">Available at RMM: {is_rmm ? 'Yes' : 'No'}</p>
        <p className="property-info-text">Property type: {propertyType}</p>
      </div>
      <div className="property-info-right">
        <img className="property-image" src={imageLink} alt={fullName} />
      </div>
    </div>
    <a className="property-marketplace-link" href={marketplaceLink} target="_blank" rel="noopener noreferrer">
      Official Marketplace at Realt.co
    </a>
    {propertyOffers && (
      <div className="property-offers">
        <h3>Available Offers</h3>
        <table className="property-offers-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {propertyOffers.map((offer, index) => (
              <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                <td>
                  <a href={createOfferLink(offer.id, 0)} target="_blank" rel="noopener noreferrer">
                    {offer.id}
                  </a>
                </td>
                <td>{convertPriceToDollars(offer.buyerToken.id, offer.price)}</td>
                <td>{parseFloat(web3.utils.fromWei(offer.quantity)).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
);

    
}
export default PropertyInfo;